import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Step9ContactConfirmation from './Step9ContactConfirmation'
import Confetti from 'react-confetti'

import {
    Box,
    Button,
    Heading,
    Stack,
    Text
} from '@chakra-ui/react'

const StepFinal = (props) => {
    const [userIsConfirmed, setUserIsConfirmed] = useState(false)
    return (
        <Box>
            <Box>
                <Heading mb="1rem">
                    <FormattedMessage id="form.step.11.congratulations.captain" />
                    <span role="img" aria-label="Bravo">👍</span>
                </Heading>
                {userIsConfirmed ?
                    <Stack spacing={{ base: 2, lg: 4 }} shouldWrapChildren={true}>
                        <Text mb="1rem">
                            {/* L'annonce sera en ligne d'ici quelques minutes le temps de quelques dernières vérifications. */}
                            <FormattedMessage id="form.step.11.notice.2" />
                        </Text>

                        <Step9ContactConfirmation
                            user={props.user}
                            userIsConfirmed={() => { setUserIsConfirmed(true) }}
                        />
                    </Stack>
                    :
                    <Stack spacing={{ base: 2, lg: 4 }} shouldWrapChildren={true}>
                        {window ?
                            <Confetti
                                recycle={false}
                                width={window.innerWidth}
                                height={window.innerHeight}
                            />
                            : null}
                        <Text>
                            <FormattedMessage id="form.step.11.notice.4" />.
                        </Text>
                        <Text>
                            <FormattedMessage id="form.step.11.notice.5" />.
                        </Text>
                        <Button size="lg" as={GatsbyLink} to='/'>
                            <FormattedMessage id="form.step.11.go.back.to.homepage" />
                        </Button>
                    </Stack>
                }
            </Box>
        </Box>
    )
}

export default StepFinal