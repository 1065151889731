import React from "react";
// import CustomRadio from "./customRadio";

import {
  Alert,
  AlertIcon,
  RadioButtonGroup,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { MyRadio } from "./customRadio";

const Step2Univers = (props) => {
  const { typology, refUnivers } = props;

  // return (<div>coucou</div>)

  return (
    <Box>

      <Alert status="success" mb={4}>
        <AlertIcon />

        {/* Nous allons prendre la saisie pour */}
        <FormattedMessage id="form.step2.notice1" />{' '}
        {(typology === 'isBoat') ? <FormattedMessage id="form.step2.your.boat" /> : null}
        {(typology === 'isSail') ? <FormattedMessage id="form.step2.your.sails" /> : null}
        {(typology === 'isPart') ? <FormattedMessage id="form.step2.your.parts" /> : null}
        {(typology === 'isTrolley') ? <FormattedMessage id="form.step2.your.trolley" /> : null}
        . <FormattedMessage id="form.step2.notice2" />
        {/* {(typology === 'isBoat') ? ' du bateau' : null}
        {(typology === 'isSail') ? ' des voiles' : null}
        {(typology === 'isPart') ? ' du matériel' : null}
        {(typology === 'isTrolley') ? ' de la remorque' : null} */}
        {' '}<FormattedMessage id="form.step2.notice3" />
        {/* {(typology === 'isBoat') ? ' voire un inventaire .doc ou .pdf' : null} */}
        .
      </Alert>

      <FormControl>
        <FormLabel htmlFor="Univers">
          {typology === "isBoat"
            ? <FormattedMessage id="form.step2.its.a" />
            : typology === "isSail"
              ? <FormattedMessage id="form.step2.of" />
              : typology === "isPart" || typology === "isTrolley"
                ? <FormattedMessage id="form.step2.for.a" />
                : null}{' '}?
        </FormLabel>

        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <MyRadio
            field='refUnivers'
            check={refUnivers === '4A30YD7j2K7y2ti3ZGxwwg'}
            value="4A30YD7j2K7y2ti3ZGxwwg"
            handleChange={props.handleChange}
          >
            <FormattedMessage id="form.step2.catamaran" />
          </MyRadio>
          <MyRadio
            field='refUnivers'
            check={refUnivers === '4zJDaRiNg9nlD69B9U6Ot'}
            value="4zJDaRiNg9nlD69B9U6Ot"
            handleChange={props.handleChange}
          >
            <FormattedMessage id="form.step2.sailing.dinghy" />
          </MyRadio>

          <MyRadio
            field='refUnivers'
            check={refUnivers === '1YRU4xM28ZGl3GpLvUvrUj'}
            value="1YRU4xM28ZGl3GpLvUvrUj"
            handleChange={props.handleChange}
          >
            <FormattedMessage id="form.step2.dayboat" />
          </MyRadio>

          <MyRadio
            field='refUnivers'
            check={refUnivers === '40Lc3C7psKGrDdTZlwejh8'}
            value="40Lc3C7psKGrDdTZlwejh8"
            handleChange={props.handleChange}
          >
            <FormattedMessage id="form.step2.offshore.racing.boat" />
          </MyRadio>

          <MyRadio
            field='refUnivers'
            check={refUnivers === '1Ax3Jw9LIEgogxpj6dG636'}
            value="1Ax3Jw9LIEgogxpj6dG636"
            handleChange={props.handleChange}
          >
            <FormattedMessage id="form.step2.cruising.sailboat" />
          </MyRadio>

        </Flex>
        {/* <RadioButtonGroup
          id="Univers"
          defaultValue={null}
          onChange={(value) => {
            props.handleChange("refUnivers", value);
            if (value === "4A30YD7j2K7y2ti3ZGxwwg") {
              props.handleChange("refUniversCode", "isCatamaran");
            }
            if (value === "4zJDaRiNg9nlD69B9U6Ot") {
              props.handleChange("refUniversCode", "isDinghy");
            }
            if (value === "1YRU4xM28ZGl3GpLvUvrUj") {
              props.handleChange("refUniversCode", "isDayboat");
            }
            if (value === "40Lc3C7psKGrDdTZlwejh8") {
              props.handleChange("refUniversCode", "isRacingBoat");
            }
            if (value === "1Ax3Jw9LIEgogxpj6dG636") {
              props.handleChange("refUniversCode", "isCruisingBoat");
            }
          }}
          isInline
        >
          <CustomRadio
            check={refUnivers === '4A30YD7j2K7y2ti3ZGxwwg'}
            value="4A30YD7j2K7y2ti3ZGxwwg">
            <FormattedMessage id="form.step2.catamaran" />
          </CustomRadio>
          <CustomRadio
            check={refUnivers === '4zJDaRiNg9nlD69B9U6Ot'}
            value="4zJDaRiNg9nlD69B9U6Ot">
            <FormattedMessage id="form.step2.sailing.dinghy" />
          </CustomRadio>
          <CustomRadio
            check={refUnivers === '1YRU4xM28ZGl3GpLvUvrUj'}
            value="1YRU4xM28ZGl3GpLvUvrUj">
            <FormattedMessage id="form.step2.dayboat" />
          </CustomRadio>
          <CustomRadio
            check={refUnivers === '40Lc3C7psKGrDdTZlwejh8'}
            value="40Lc3C7psKGrDdTZlwejh8">
            <FormattedMessage id="form.step2.offshore.racing.boat" />
          </CustomRadio>
          <CustomRadio
            check={refUnivers === '1Ax3Jw9LIEgogxpj6dG636'}
            value="1Ax3Jw9LIEgogxpj6dG636">
            <FormattedMessage id="form.step2.cruising.sailboat" />
          </CustomRadio>
        </RadioButtonGroup> */}
      </FormControl>
    </Box >
  )
}

export default Step2Univers