import React from "react"
import Loadable from 'react-loadable'
import { navigate } from "gatsby"

import axios from "axios"

import "./style.css"

import { Alert, AlertIcon, Box, Button, Divider, Heading, Stack, Text } from "@chakra-ui/react";


import Step1Typology from "./Step1Typology";
import Step2Univers from "./Step2Univers";
import Step3Model from "./Step3_0Model";
import Step35Year from "./Step3_5Year";
import Step35Brand from "./Step3_5Brand";
// import Step4Location from "./Step4Location";
import Step5Title from "./Step5Title";
// import Step65Documents from "./Step6_5Documents";
import Step7Pictures from "./Step7Pictures";
import Step8Price from "./Step8Price";
import Step9Contact from "./Step9Contact";
import Step9ContactConfirmation from "./Step9ContactConfirmation";
import StepFinal from './StepFinal'

// import PremiumOffers from "./PremiumOffers";
import Validation from "./Validation";
// import '../AdCard/annoncesWysiwyg.scss'

import { FormattedMessage, injectIntl } from "react-intl"
import LocationField from "./LocationField"
import Debug from "../../utils/Debug"
import Step4LocationBis from "./Step4LocationBis"
var markdown = require("markdown").markdown;


// import * as Sentry from '@sentry/react';
// Sentry.init({ dsn: "https://a748da6fdcbc47b2b75b5b45820a8c14@o417656.ingest.sentry.io/5318621" });

// const ReactMarkdown = require('react-markdown')

class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tmpID: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      locale: this.props.intl.locale,
      currentStep: 0,
      action: false,
      isBoat: false,
      isSail: false,
      isPart: false,
      isTrolley: false,
      contentEditorVisible: false,
      typology: null,
      refUnivers: null,
      refUniversCode: null,
      location: null,
      geoCoordinates: null,
      refModel: null,
      suggestedVersions: [],
      refVersion: null,
      modelName: null,
      refBrand: null,
      brandName: null,
      description: null,
      documents: [],
      pictures: [],
      titleEn: null,
      titleFr: null,
      price: 0,
      currency: 'EUR',
      currenciesRates: null,
      year: 0,
      formRate: 0,
      formRateComment: null,
      validation: "email",
      source: process.env.GATSBY_AD_SOURCE,
      error: null,
      imagesRepaint: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.publish = this.publish.bind(this);
    this.setFormStep = this.setFormStep.bind(this);
  }

  componentDidMount() {
    const windowGlobal = typeof window !== 'undefined' && window
    // this.getCurrenciesRates()
  }

  publish() {
    var _this = this;
    axios.post(process.env.GATSBY_API_URL + "/annonce/", {
      ...this.state,
      user: this.props.user
    })
      .then(function (response) {
        _this.setState({ currentStep: 12 })
        // navigate("/")
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // Sentry.captureException(error, _this.state);
        _this.setState({ error: true })
      })
      .then(function () {
        // always executed
      })
  }


  handleChange(name, value) {
    // console.log(name, value);
    const { typology } = this.state;
    this.setState({
      [name]: value
    });
    let nextStep;
    switch (name) {
      case "typology":
        nextStep = 1;
        this.setState({ [value]: true });
        // this.props.hasStarted();

        break;
      case "refUnivers":
        nextStep = 2;
        break;
      case "refUniversCode":
        nextStep = 2;
        break;
      case "refModel":
        nextStep = typology === 'isBoat' ? 3.5 : 4;
        break;
      case "refVersion":
        nextStep = typology === 'isBoat' ? 3.5 : 4;
        break;
      case "modelName":
        nextStep = typology === 'isBoat' ? 3.5 : 4;
        break;
      case "year":
        nextStep = 3.6;
        break;
      case "refBrand":
        nextStep = 4;
        break;
      case "brandName":
        nextStep = 4;
        break;
      case "location":
        nextStep = 5;
        break;
      case "geoCoordinates":
        nextStep = 5;
        break;
      case "title":
        nextStep = 6;
        this.setState({ contentEditorVisible: true });
        break;
      case "titleFr":
        nextStep = 6;
        this.setState({ contentEditorVisible: true });
        break;
      case "titleEn":
        nextStep = 6;
        this.setState({ contentEditorVisible: true });
        break;
      case "description":
        nextStep = typology === 'isBoat' ? 8 : 8;
        break;
      case "documents":
        nextStep = 7;
        break;
      case "pictures":
        nextStep = 7;
        break;
      case "currency":
        nextStep = 9;
      case "price":
        nextStep = 9;
        break;
      case "formRate":
        nextStep = 11;
        break;
      case "formRateComment":
        nextStep = 11;
        break;
      default:
        nextStep = 0;
    }
    if (nextStep > this.state.currentStep) {
      this.setState({
        currentStep: nextStep
      }, () => {
        console.log(this.state, 'nextStep : ' + nextStep);
      });
    }
  }

  setFormStep(step) {
    this.setState({ currentStep: step });
  }

  render() {
    const {
      currentStep,
      tmpID,
      action,
      typology,
      refUnivers,
      refUniversCode,
      refVersion,
      modelName,
      refBrand,
      brandName,
      year,
      titleEn,
      titleFr,
      description,
      pictures,
      uploadDir,
      country,
      city,
      documents,
      location,
      contentEditorVisible,
      price,
      currency,
      WbUserToken,
      error,
      imagesRepaint
    } = this.state;

    const Step6ContentEditor = Loadable({
      loader: () => import('./Step6ContentEditor'),
      loading() {
        return <Box>Chargement de l'éditeur...</Box>
      }
    });
    const getAllData = () => {
      let values = this.state
      values['user'] = this.props.user
      return values
    }
    return (
      <>
        {/* <Debug
          data={this.state}
          visible={false}
        /> */}
        {error ?
          <Alert status="error" mb={4}>
            <AlertIcon />
            Une error est survenue pendant l'enregistrement de votre annonce. Pouvez-vous envoyer les détails de celle-ci (Titre, Description, Photos) à vhf@wanaboat.fr pour une publication manuelle ?
          </Alert>
          : null}
        {(currentStep > 10) ?
          currentStep === 11 ?
            <Validation
              handleChange={this.handleChange}
              publish={this.publish}
            />
            : <StepFinal user={this.props.user} />

          :
          <Box pb={["5rem", "10rem"]}>
            <Heading
              as="h1"
              fontSize={{ base: 24, lg: 30 }}
              mb={{ base: 4, lg: 10 }}><FormattedMessage id="form.lets.go.captain" />&nbsp;!</Heading>
            <Stack spacing={10} shouldWrapChildren={true}>
              {currentStep >= 0 ? (
                <Step1Typology
                  typology={typology}
                  action={action}
                  handleChange={this.handleChange} />
              ) : null}
              {currentStep >= 1 ? (
                <Step2Univers
                  typology={typology}
                  refUnivers={refUnivers}
                  handleChange={this.handleChange}
                />
              ) : null}

              {currentStep >= 2 ? (
                <Step3Model
                  key={refUnivers}
                  univers={refUnivers}
                  refUniversCode={refUniversCode}
                  refVersion={refVersion}
                  typology={typology}
                  handleChange={this.handleChange}
                />
              ) : null}

              {currentStep >= 2.5 && typology === 'isBoat' ? (
                <Step35Year
                  year={year}
                  typology={typology}
                  handleChange={this.handleChange}
                />
              ) : null}

              {currentStep >= 3.6 ? (
                <Step35Brand
                  key={refUnivers}
                  refUnivers={refUnivers}
                  refBrand={refBrand}
                  brandName={brandName}
                  typology={typology}
                  handleChange={this.handleChange}
                />
              ) : null}

              {currentStep >= 4 ? (
                // <Step4Location
                //   univers={refUnivers}
                //   typology={typology}
                //   handleChange={this.handleChange}
                // />

                // <Step4Location
                //   univers={refUnivers}
                //   typology={typology}
                //   handleChange={this.handleChange}
                // />

                <Step4LocationBis
                  univers={refUnivers}
                  typology={typology}
                  handleChange={this.handleChange}
                >

                  <LocationField
                    setFieldValue={(key, value) => this.handleChange(key, value)}
                    location={this.state.location}
                  />
                </Step4LocationBis>

              ) : null}

              {currentStep >= 5 ? (
                <Step5Title
                  key={city}
                  action={action}
                  typology={typology}
                  modelName={modelName}
                  brandName={brandName}
                  location={location ? location : null}
                  country={country}
                  city={city}
                  handleChange={this.handleChange}
                />
              ) : null}


              {currentStep >= 6 ? (
                <Step7Pictures

                  tmpID={tmpID}
                  adTitle={titleEn}
                  typology={typology}
                  handleChange={this.handleChange}
                  pictures={pictures}
                  key={imagesRepaint}
                  triggerRepaint={(value) => this.setState({ 'imagesRepaint': value })}
                />
              ) : null}

              {currentStep >= 7 ? (
                contentEditorVisible ?
                  <Step6ContentEditor
                    typology={typology}
                    handleChange={this.handleChange}
                    closeEditor={() => { this.setState({ contentEditorVisible: false }) }}
                    originalDescription={description}
                    uploadDir={tmpID}
                    documents={documents}
                  />
                  :
                  <Box>
                    <Box borderRadius="6px" className="wysiwyg" p="10px" border="solid 1px" borderColor="gray.100">
                      <Box
                        className="wysiwyg"
                        dangerouslySetInnerHTML={{
                          "__html": markdown.toHTML(description == '' ? 'Pas de description' : description)
                        }}
                      />
                    </Box>
                    <Button
                      mt={2}
                      onClick={() => {
                        this.setState({ contentEditorVisible: true });
                      }}
                      size="xs"
                      variant="outline"
                    >
                      Modifier
                    </Button>
                  </Box>
              ) : null}

              {/* {currentStep >= 6.5 && typology === 'isBoat' ? (
                <Box>
                  <Step65Documents
                    tmpID={tmpID}
                    typology={typology}
                    adTitle={title}
                    handleChange={this.handleChange}
                  />
                </Box>
              ) : null} */}



              {currentStep >= 8 ? (
                <Step8Price
                  price={price}
                  typology={typology}
                  currency={currency}
                  handleChange={this.handleChange}
                />
              ) : null}

              {currentStep >= 9 && price ? (
                <Box>
                  <Divider my={5} />
                  {/* <Text color="gray.700" mb="1rem">
                      <FormattedMessage id="form.step.10.fully.fill.notice" />
                    </Text> */}
                  <Button
                    w={{ base: '100%', md: 'auto' }}
                    bg="green.300"
                    color="white"
                    size="lg"
                    _hover={{
                      backgroundColor: 'green.400',
                      color: 'white'
                    }}
                    // disabled={(
                    //   !user_email
                    //   ||
                    //   !user_name
                    //   ||
                    //   !user_phone
                    //   ||
                    //   user_email != user_email_confirmation
                    // ) ? true : false}
                    onClick={() => { this.setState({ 'currentStep': 11 }) }}
                  >
                    <FormattedMessage id="form.step10.confirm.ad.and.finalize" /> →
                  </Button>
                </Box>
              ) : null}
            </Stack>
          </Box>
        }
      </>
    );
  }
}

export default injectIntl(Form)