import React from "react";
import CustomRadio, { MyRadio } from "./customRadio";
import {
    RadioGroup,
    Flex,
    FormControl,
    FormLabel,
    Stack,
    Radio,
    HStack,
    useRadioGroup,
    Box,
    Button
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import Debug from "../../utils/Debug";



const Step1Typology = (props) => {
    const { action, typology } = props


    return (
        <Stack spacing={{ base: 5, lg: 6 }}>
            <FormControl>
                <FormLabel htmlFor="action"><FormattedMessage id="form.step1.title" />&nbsp;?</FormLabel>
                <Box
                    name='typology'
                >
                    <Flex
                        flexDir={{ base: 'column', lg: 'row' }}
                    >
                        <MyRadio
                            field='action'
                            check={action === 'sell'}
                            value="sell"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.sell" />
                        </MyRadio>
                        <MyRadio
                            field='action'
                            check={action === 'rent'}
                            value="rent"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.rent" />
                        </MyRadio>
                        <MyRadio
                            field='action'
                            check={action === 'search'}
                            value="search"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.search" />
                        </MyRadio>

                        {/* <CustomRadio check={action === 'rent'} value="rent"><FormattedMessage id="form.step1.rent" /></CustomRadio> */}
                        {/* <CustomRadio check={action === 'search'} value="search"><FormattedMessage id="form.step1.search" /></CustomRadio> */}
                    </Flex>

                </Box>
            </FormControl>

            {action ?
                <FormControl>
                    <FormLabel htmlFor="typology"><FormattedMessage id="main.what" />&nbsp;?</FormLabel>

                    <Flex
                        flexDir={{ base: 'column', lg: 'row' }}
                    >

                        <MyRadio
                            field='typology'
                            check={action === 'isBoat'}
                            value="isBoat"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.complete.boat" />
                        </MyRadio>
                        <MyRadio
                            field='typology'
                            check={action === 'isSail'}
                            value="isSail"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.complete.sails" />
                        </MyRadio>
                        <MyRadio
                            field='typology'
                            check={action === 'isPart'}
                            value="isPart"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.complete.parts" />
                        </MyRadio>
                        <MyRadio
                            field='typology'
                            check={action === 'isTrolley'}
                            value="isTrolley"
                            handleChange={props.handleChange}
                        >
                            <FormattedMessage id="form.step1.complete.trolley" />
                        </MyRadio>
                    </Flex>
                </FormControl>
                : null}
        </Stack>
    )
}

export default Step1Typology