import { Divider, Image } from '@chakra-ui/react';
import * as React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import Debug from '../Debug';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Debug from '../../utils/Debug';




const LocationField = ({ setFieldValue, location }) => {

    const [value, setValue] = React.useState(null);

    React.useEffect(
        () => {

            if (value && value.value.place_id) {
                console.log(value);
                setFieldValue('address', value.value.description);

                geocodeByPlaceId(value && value.value ? value.value.place_id : null)
                    .then(results => {
                        // console.log(results);
                        setFieldValue('location',
                            {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            })

                        results[0].address_components.forEach(line => {

                            if (line.types.includes('route')) {
                                setFieldValue('street', line.long_name)
                            }

                            if (line.types.includes('locality')) {
                                setFieldValue('city', line.long_name)
                            }

                            if (line.types.includes("administrative_area_level_1")) {
                                setFieldValue('region', line.long_name)
                            }

                            if (line.types.includes("country")) {
                                setFieldValue('country', line.long_name)
                            }

                            if (line.types.includes("postal_code")) {
                                setFieldValue('zipCode', line.long_name)
                            }

                        });
                        setFieldValue('')
                    })
                    .catch(error => console.error(error));
            }


        }, [value]
    )


    const MapConfirmation = () => {

        if (!location) return null;

        return (
            <>
                <Divider
                    borderColor="gray.200"
                    my={5}
                />
                {/* <Text><FormErrorMessage id="form.step4.where.is.it.here" /></Text> */}
                <Image
                    width="300"
                    alt="Localisation de votre annonce"
                    height="150"
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat},${location.lng}&zoom=7&autoscale=1&size=300x150&maptype=roadmap&key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${location.lat},${location.lng}`}
                />
            </>
        )
    }

    return (
        <div>

            <GooglePlacesAutocomplete
                apiKey={"AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U"}
                selectProps={{
                    value,
                    onChange: setValue,
                    placeholder: 'Lorient, Barcelone, San Francisco ?',
                    // noOptionsMessage: process.env.GATSBY_LANG === 'fr' ? 'Saissez la localisation' : 'Fill the location'
                    noOptionsMessage: () => process.env.GATSBY_LANG === 'fr' ? 'Saissez la localisation' : 'Fill the location'
                }}
                apiOptions={{ language: process.env.GATSBY_LANG }}

            />

            <MapConfirmation />

            {/* <Debug data={ value } /> */}
        </div>
    )
}

export default LocationField;