import React, { Component } from "react";
import {
  Heading,
  Button,
  Text,
  RadioButtonGroup,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Icon,
  Textarea,
  Flex
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { injectIntl } from 'react-intl'
import { MyRadio } from "./customRadio";
import { BiStar } from "react-icons/bi";
// import * as Sentry from '@sentry/react';
// Sentry.init({dsn: "https://a748da6fdcbc47b2b75b5b45820a8c14@o417656.ingest.sentry.io/5318621"});
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import Debug from "../../utils/Debug";
class Validation extends Component {


  constructor(props) {
    super(props);
    this.state = {
      formRate: false,
      isUploading: false,
      formRateComment: 0,
    };
  }



  render() {
    const { formRate, isUploading, formRateComment } = this.state;

    const StarOn = () => (
      <Box color='yellow.300'>
        <AiFillStar />
      </Box>
    )

    const StarOff = () => (
      <Box color='yellow.300'>
        <AiOutlineStar />
      </Box>
    )

    return (
      <Box>
        {/* <Box as='header'>
          <Button
            onClick={() => { this.props.setFormStep(10) }}>Retour</Button>
        </Box> */}
        <Box>
          <Heading>
            {/* C'est presque fini mon Capitaine&nbsp;! */}
            <FormattedMessage id="form.step11.title" />&nbsp;!
          </Heading>
          <FormControl mt={10}>
            {!formRate ?
              <>
                <FormLabel fontSize="lg" htmlFor="formRate">
                  <FormattedMessage id="form.step11.what.did.you.think" />
                  {/* Comment avez-vous trouvé notre formulaire */}
                  &nbsp;?
                </FormLabel>
                {/* <RadioButtonGroup
                  id="formRate"
                  onChange={(value) => {
                    this.setState({ "formRate": value });
                    this.props.handleChange('formRate', value);
                  }}
                > */}
                <Flex
                  flexDir={{ base: 'column', lg: 'row' }}
                >
                  <MyRadio
                    value="5"
                    outline
                    handleChange={(key, value) => {
                      this.setState({ "formRate": value });
                      this.props.handleChange('formRate', value);
                    }}
                  >
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <Text>Super</Text>
                  </MyRadio>
                  <MyRadio
                    value="4"
                    outline
                    handleChange={(key, value) => {
                      this.setState({ "formRate": value });
                      this.props.handleChange('formRate', value);
                    }}
                  >
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <StarOff />
                    <Text><FormattedMessage id="form.step11.correct" /></Text>
                  </MyRadio>
                  <MyRadio
                    value="3"
                    outline
                    handleChange={(key, value) => {
                      this.setState({ "formRate": value });
                      this.props.handleChange('formRate', value);
                    }}
                  >
                    <StarOn />
                    <StarOn />
                    <StarOn />
                    <StarOff />
                    <StarOff />
                    <Text>
                      <FormattedMessage id="form.step11.meh" />
                    </Text>
                  </MyRadio>
                  <MyRadio
                    value="2"
                    outline
                    handleChange={(key, value) => {
                      this.setState({ "formRate": value });
                      this.props.handleChange('formRate', value);
                    }}
                  >
                    <StarOn />
                    <StarOn />
                    <StarOff />
                    <StarOff />
                    <StarOff />
                    <Text><FormattedMessage id="form.step11.its.sucks" /></Text>
                  </MyRadio>
                  <MyRadio
                    value="3"
                    outline
                    handleChange={(key, value) => {
                      this.setState({ "formRate": value });
                      this.props.handleChange('formRate', value);
                    }}
                  >
                    <StarOn />
                    <StarOff />
                    <StarOff />
                    <StarOff />
                    <StarOff />
                    <Text><FormattedMessage id="form.step11.its.awful" /></Text>
                  </MyRadio>
                </Flex>
                <FormHelperText id="email-helper-text">
                  <FormattedMessage id="form.step11.notice" />
                </FormHelperText>
              </>
              : null}
            {formRate && formRate < 5 ?
              <FormControl mt={10}>
                <Button mb="1rem" size="sm" onClick={() => { this.setState({ formRate: undefined }) }}>
                  <FormattedMessage id="main.back" />
                </Button>
                <br />
                <FormLabel fontSize="lg" htmlFor="formRate">
                  <FormattedMessage id="form.step.11.what.could.be.improved" />
                </FormLabel>
                <Textarea
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      var value = undefined
                    } else {
                      var value = e.target.value
                    }
                    this.setState({ "formRateComment": value });
                    this.props.handleChange('formRateComment', value);

                  }}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      var value = undefined
                    } else {
                      var value = e.target.value
                    }
                    this.setState({ "formRateComment": value });
                    this.props.handleChange('formRateComment', value);
                    console.log('value', value)

                  }}
                  //  placeholder="Parce qu'on veut vraiment qu'il soit parfait, pour vous satisfaire à 100% la prochaine fois.">
                  placeholder={this.props.intl.formatMessage({ id: 'form.step.11.placeholder' })}
                >
                </Textarea>

              </FormControl>
              : null
            }
            {formRate > 4 ?
              <Box>
                <Text>
                  {/* Voilà qui fait vraiment plaisir ! Bonne vente Capitaine ! */}
                  <FormattedMessage id="form.step.11.five.stare.rate.comment" />
                </Text>
              </Box> : null}
            {parseInt(formRate) === 5 || formRate && formRateComment ?
              <Button
                isLoading={isUploading}
                loadingText="Création en cours"
                onClick={() => {
                  this.setState({ isUploading: true });
                  this.props.publish();
                }}
                mt={10} w={{ base: '100%', md: 'auto' }} colorScheme="green" size="lg">
                <FormattedMessage id="form.step11.publish.your.ad" />
              </Button>
              : null}
          </FormControl>

        </Box>
      </Box>
    )
  }
}

export default injectIntl(Validation);
