import React, { Component } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText
} from "@chakra-ui/react";
import { injectIntl, FormattedMessage } from 'react-intl'
import Debug from "../../utils/Debug";

class Step5Title extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      titleFr: null,
      titleEn: null,
      isValid: false
    }
  }
  componentDidMount() {

    const { action, typology, location, modelName, brandName, city, country } = this.props

    const actionLabelEn = () => {
      if (action === 'sell') {
        return 'for sale'
      }
      if (action === 'rent') {
        return 'for rent'
      }
      if (action === 'search') {
        return 'look for'
      }
    }
    switch (typology) {
      case "isBoat":
        this.setState({
          titleEn: `${modelName} ${brandName} ${actionLabelEn()} ${city ? 'in ' + city : null}`
        });
        break;
      case "isSail":
        this.setState({
          titleEn: `${modelName} sails ${actionLabelEn()} ${city ? 'in ' + city : null}`
        });
        break;
      case "isPart":
        this.setState({
          titleEn: `${modelName} parts ${actionLabelEn()} ${city ? 'in ' + city : null}`
        });
        break;
      case "isTrolley":
        this.setState({
          titleEn: `Trolley / Trailer ${actionLabelEn()} ${city ? 'in ' + city : null}`
        });
        break;
      default:
        this.setState({
          titleEn: `Parts ${actionLabelEn()} ${city ? 'in ' + city : null}`
        });
        break;
    }

    const actionLabelFr = () => {
      if (action === 'sell') {
        return 'à vendre'
      }
      if (action === 'rent') {
        return 'à louer'
      }
      if (action === 'search') {
        return 'cherche'
      }
    }
    switch (typology) {
      case "isBoat":
        this.setState({
          titleFr: `${modelName} ${brandName} ${actionLabelFr()} ${city ? 'à ' + city : null}`
        });
        break;
      case "isSail":
        this.setState({
          titleFr: `Voile(s) de ${modelName} ${actionLabelFr()} ${city ? 'à ' + city : null}`
        });
        break;
      case "isPart":
        this.setState({
          titleFr: `Pièces de ${modelName} ${actionLabelFr()} ${city ? 'à ' + city : null}`
        });
        break;
      case "isTrolley":
        this.setState({
          titleFr: `Remorque ${actionLabelFr()} ${city ? 'à ' + city : null}`
        });
        break;
      default:
        this.setState({
          titleFr: `${actionLabelFr()} ${city ? 'à ' + city : null}`
        });
        break;
    }

  }
  render() {
    const { isValid, title } = this.state;
    const handleChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };
    return (
      <FormControl>
        <FormLabel htmlFor="Title">
          <FormattedMessage id="form.step5.title.of.your.ad" />&nbsp;:
        </FormLabel>
        <Input
          id="Title"
          disabled={isValid}
          placeholder="Un super bateau que je vous propose"
          onChange={handleChange}
          name={this.props.intl.locale === 'fr' ? 'titleFr' : 'titleEn'}
          value={this.state.titleFr ? this.state.titleFr : this.state.titleEn ? this.state.titleEn : ''}
          size="md"
        />
        {!isValid ? (
          <FormHelperText id="email-helper-text">
            <FormattedMessage id="form.step5.notice" />
            {/* Voici notre proposition, si vous souhaitez la modifier, c'est possible. */}
          </FormHelperText>
        ) : null}
        {!isValid ? (
          <Button
            mt={4}
            colorScheme="teal"
            variant="outline"
            type="submit"
            onClick={() => {
              this.props.handleChange("title", this.state.title);
              this.props.handleChange("titleFr", this.state.titleFr);
              this.props.handleChange("titleEn", this.state.titleEn);
              this.setState({ isValid: true });
            }}
          >
            <FormattedMessage id="form.step5.validate.this.title" />
          </Button>
        ) : (
          <Button
            mt={2}
            onClick={() => {
              this.setState({ isValid: false });
            }}
            size="xs"
            variant="outline"
          >
            <FormattedMessage id="main.update" />
          </Button>
        )}
      </FormControl>
    );
  }
}
export default injectIntl(Step5Title)
