import React, { Component } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormLabel,
  Stack,
  Text
} from "@chakra-ui/react";

import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";
import IntlPhoneNumber from '../ad/IntlPhoneNumber'
class Step9ContactConfirmation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      userPhone: null,
      isConfirmed: false
    }
  }

  componentDidMount() {
    var _this = this
    axios.get(`${process.env.GATSBY_API_URL}/user/${this.props.user}`)
      .then(function (response) {
        console.log(response)
        _this.setState({
          userName: response.data.name,
          userPhone: response.data.phone
        })
        if (response.data.is_trusted) {
          _this.props.handleChange('isTrustedUser', true)
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    var _this = this
    const { userPhone, userName, isConfirmed } = this.state
    const updateContact = () => {
      axios.patch(
        `${process.env.GATSBY_API_URL}/user/${this.props.user}`,
        {
          name: userName,
          phone: userPhone
        }
      ).then((response) => {
        _this.setState({ isConfirmed: true })
        this.props.userIsConfirmed()
      })
    }

    return (
      <FormControl>
        {
          // userName && user_email ?
          !isConfirmed ?
            <Stack spacing=".5rem">
              <FormLabel htmlFor="price">
                {/* C'est bien vous&nbsp;? (Pouvez-vous compléter les infos manquantes ?) */}
                <FormattedMessage id="form.step9.is.it.you" />
              </FormLabel>
              <InputGroup>
                <InputLeftAddon w="70px" children={this.props.intl.formatMessage({ id: 'main.name' })} />
                <Input
                  borderRadius="0 3px 3px 0"
                  size="md"
                  placeholder="Nom"
                  name="userName"
                  value={`${userName}`}
                  onChange={(e) => {
                    this.setState({ userName: e.target.value })
                  }
                  }
                />
              </InputGroup>
              <IntlPhoneNumber
                value={userPhone}
                updatePhoneNumber={(value) => {
                  this.setState({ userPhone: value })
                }}
              />
              <Stack isInline spacing=".5rem" my="1rem">
                <Button
                  colorScheme="blue"
                  disabled={!userName || !userPhone}
                  onClick={() => { updateContact() }}>
                  {(!userName || !userPhone) ?
                    <FormattedMessage id="form.step9.fill.your.infos.first" />
                    : <FormattedMessage id="form.step9.i.valid" />
                  }
                </Button>
              </Stack>
            </Stack>
            // : null 
            :
            null
        }
      </FormControl>
    );
  }

}

export default injectIntl(Step9ContactConfirmation)